export const domainData = [
    {
        name: "soralaunch.com",
        href: "https://dan.com/buy-domain/soralaunch.com",
    },
    {
        name: "sorastop.com",
        href: "https://dan.com/buy-domain/sorastop.com",
    },
    {
        name: "soraspam.com",
        href: "https://dan.com/buy-domain/soraspam.com",
    },
    {
        name: "sorasequel.com",
        href: "https://dan.com/buy-domain/sorasequel.com",
    },
    {
        name: "voteforbezos.com",
        href: "https://dan.com/buy-domain/voteforbezos.com",
    },
];
